import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Projects from "../components/Projects"
import Contact from "../components/Contact"
import page from "../templates/page"
import Soon from "../components/Soon"
import styled from "styled-components"

const IntroBox = styled.section`
  background: #4a5054;
  width: 100%;
`
const IntroContent = styled.div`
  background: #000;
  min-height: 150px;
  border: 1px solid palegoldenrod;
  text-align: center;
  padding: 25px;
`
function Homepage() {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { path: { eq: "/" } }) {
        edges {
          node {
            title
            path
            template
            content
          }
        }
      }
    }
  `)

  const pageData = data.allWordpressPage.edges[0].node
  const [offline, setOffline] = useState(true)
  return (
    <Layout>
      {/* <IntroBox>
        <div className="container">
          <IntroContent>
            <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
          </IntroContent>
        </div>
      </IntroBox>
      <Projects />
      <Contact /> */}
      <Soon />
    </Layout>
  )
}

export default Homepage

// export const pageQuery = graphql`
//   query {
//     allWordpressPage(filter: { path: { eq: "/" } }) {
//       edges {
//         node {
//           title
//           path
//           template
//         }
//       }
//     }
//   }
// `
