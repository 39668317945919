import React from "react"
import Layout from "../components/layout"
import Projects from "../components/Projects"
// import Header from "./header"

export default ({ pageContext }) => (
  <Layout>
    <Projects />
  </Layout>
)
