import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

const Section = styled.section`
  padding-top: 50px;
`

const ProjectItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const ProjectItem = styled.div`
  flex: 1;
  margin: 45px 25px 45px 0;
`
const ProjectCard = styled(Row)`
  margin-left: 0;
  margin-bottom: 40px;
  transition: box-shadow 0.3s;
  margin-right: 0;
  .project {
    &__info {
      border: 1px solid #f1f1f1;
      padding: 50px 50px 40px 30px;
    }
    &__title {
      font-size: 18px;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 700;
    }
    &__description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 45px;
    }
  }
`
const ProjectImage = styled.img`
  max-width: 100%;
  height: 100%;
`
const ProjectsHeaderText = styled.div`
  margin-bottom: 40px;
  width: 100%;
`

const EntryHeader = styled.header`
  margin-top: 20px;
  font-size: 2rem;
`

const Projects = () => {
  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const data = useStaticQuery(graphql`
    query ProjectQuery {
      allWordpressWpProject {
        edges {
          node {
            id
            title
            content
            template
            slug
            featured_media {
              source_url
            }
          }
        }
      }

      allWordpressPage {
        edges {
          node {
            title
            path
            template
            content
            acf {
              header_logo {
                source_url
              }
            }
          }
        }
      }
    }
  `)
  console.log(data)
  const project = data.allWordpressWpProject.edges[0].node
  console.log(project)
  return (
    <Section>
      <Container>
        <Row>
          <ProjectsHeaderText>
            <div>
              <h2 className="text-center">Recent Work</h2>
            </div>
          </ProjectsHeaderText>
        </Row>
        <div className="project-cards">
          <ProjectCard className="project-card">
            <Col md={6} lg={5} className="project-card__img">
              <ProjectImage
                src={project.featured_media.source_url || ""}
                alt="project"
              />
            </Col>
            <Col md={6} lg={7} className="project__info">
              <h3 className="project__title">APp for store</h3>
              <div dangerouslySetInnerHTML={{ __html: project.content }} />
            </Col>
          </ProjectCard>
        </div>
        {/* {data.allWordpressWpProject.edges.map(projectItem => (
            <ProjectItem key={projectItem.node.id}>
              <Link to={`/project/${projectItem.node.slug}`}>
                <ProjectImage
                  src={projectItem.node.featured_media.source_url || ""}
                  alt="Thumbnail"
                />
              </Link>
              <EntryHeader>
                <h2 className="project-title">
                  <Link to={`/project/${projectItem.node.slug}`}>
                    {projectItem.node.title}
                  </Link>
                </h2>
              </EntryHeader>
              <div>
                {props.path === "/work" ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: projectItem.node.content,
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </ProjectItem>
          ))} */}
      </Container>
    </Section>
  )
}

export default Projects
