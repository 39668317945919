import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import styled from "styled-components"
import { IoLogoGithub, IoLogoLinkedin } from "react-icons/io"
const StyledContainer = styled.div`
  /* background: #00091b; */
  color: #fff;
  height: 65vh;
  text-align: center;
  h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
    padding: 40px 0 5px 0;
  }
  .dot {
    color: var(--gold-main);
  }
  p {
    text-align: center;
    margin: 18px;
    font-weight: normal;
  }
`
const StyledContainerLoading = styled.div`
  height: 100vh;
  text-align: center;
  padding-top: 40px;
  h1,
  p,
  .dot {
    display: none;
  }
`
/* .icons i {
    color: #00091b;
    background: #fff;
    height: 15px;
    width: 15px;
    padding: 13px;
    margin: 0 10px;
    border-radius: 50px;
    border: 2px solid #fff;
    transition: all 200ms ease;
    text-decoration: none;
    position: relative;
  } */
/* .icons i:hover,
  .icons i:active {
    color: #fff;
    background: none;
    cursor: pointer !important;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    text-decoration: none;
  } */

const iconStyles = {
  color: "gold",
  margin: "0 10px",
  fontSize: "3.2rem",
  borderRadius: "50px",
  border: "2px solid #fff",
  padding: "10px",
  cursor: "pointer",
}
const Soon = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000)
    return () => clearTimeout(timeout)
  }, [])
  if (loading) {
    return (
      <StyledContainerLoading>
        <Spinner animation="border" variant="warning" />
      </StyledContainerLoading>
    )
  }
  return (
    <StyledContainer className={!loading ? loading : ""}>
      <h1>
        coming soon<span className="dot">.</span>
      </h1>
      <p>Changes Are Happening.</p>
      <div className="icons">
        <a href="https://www.linkedin.com/in/jeff-lundgren-675974193/m">
          <IoLogoLinkedin style={iconStyles} />
        </a>

        <a href="https://github.com/jlundgren77">
          <IoLogoGithub style={iconStyles} />
        </a>
      </div>
    </StyledContainer>
  )
}
export default Soon
